@import './node_modules/@yaireo/tagify/src/tagify';

// меняет стиль, чтобы при наведении мышкой цвет тега оставался прежним
.keep_bg .tagify__tag {
    border-radius: 4px; // делает прямоугольник тега с загругленными углами
    --tag-hover: var(--tag-bg);
    // устанавливает цвет фона ранвый цвету тега, чтобы исправить тот случай, 
    // когда посреди тега появляется белая полоса при наведении мышкой (как будто перечеркнут текст)
        // и увеличении размера тега (при этом border увеличивается и соответственно проявляется фон тгеа, и он почему-то оказывается белым)
        background-color: var(--tag-bg);
}

/* Стили для элементов выпадающего списка */

.remove-suggestion-btn {
    color: gray;
    padding: 0.15rem 0.4rem;
    font-size: 0.8rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.remove-suggestion-btn:hover,
.remove-suggestion-btn:focus {
    color: #ffffff;
    /* Цвет текста при наведении и фокусе */
    background-color: #dc3545;
    /* Фон кнопки при наведении и фокусе */
    border-color: #dc3545;
    /* Цвет границы при наведении и фокусе */
}

/* Стили для изменения цвета иконки внутри кнопки при наведении на элемент списка */
.tagify__dropdown__item:hover .remove-suggestion-btn .bi,
.tagify__dropdown__item--active .remove-suggestion-btn .bi {
    color: white;
    /* Цвет иконки при наведении */
}

/* Изменение фона и цвета текста элементов при наведении */
.tagify__dropdown__item:hover,
.tagify__dropdown__item--active {
    background-color: $fifthColor;
    /* Цвет фона */
    color: black;
    /* Цвет текста */
}

.tagify__dropdown {
    z-index: 1050;
    border-color: $primaryColor;
}

.dropdown_inside_module {
    z-index: 1060;
}
