$primaryColor : #6629BF;
$secondaryColor: #F7FAFF;
$thirdColor: #ac9ac9;
$forthColor: #dac1ff;;
$fifthColor: #E5E5E5;
$sixthColor: #a56aff;
$seventhColor: #c3cee7;;

@import 'bootstrap.scss';
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.4/font/bootstrap-icons.css");

@import 'tabulator.scss';

@import './badges.scss';
@import './popover.scss';
@import './tagify.scss';
@import './audioPlayer.scss';

:root {
    --tagify-dd-color-primary: $primaryColor; // should be same as "$tags-focus-border-color"

}

body,
html {
    overflow: hidden;
    height: 100%;
}

.my-bg-secondaryColor {
    background-color: $secondaryColor;
}

.my-bg-primaryColor {
    background-color: $primaryColor;
}

.my-secondaryColor {
    color: $secondaryColor;
}

.my-primaryColor {
    color: $primaryColor;
}

.form-control {
    border-color: $seventhColor;
}

// Кропки на верхней nav панели
.my-nav-btn {
    background-color: none;
    border: none;
    color: $secondaryColor;
}

.my-nav-btn:hover {
    background-color: white;
    color: $primaryColor;
}

.highlight {
    background-color: rgb(255, 196, 0);
    display: inline-block;

}

// заголовок правой панели
.right-panel-header {
    // border: 1px solid $borderColor;
    background: $secondaryColor;
    position: sticky;
    top: 0;
    z-index: 1000;
    /* Убедитесь, что заголовок находится поверх других элементов */
}

.right-panel-header:hover {
    cursor: text;
}

.right-panel-footer {
    // border: 1px solid $borderColor;
    background: $secondaryColor;
    position: sticky;
    bottom: 0;
    z-index: 1001;
    /* Убедитесь, что футер находится поверх других элементов */
}

// правая панель
.right-panel {
    background-color: $secondaryColor;
    overflow-x: hidden;
}

// панель с данными записи внутри правой панели должна прокручиваться без заголовка
.entity-data-container {
    overflow-y: auto;
}

// разделитель панелей
.divider {
    cursor: ew-resize;
    // background-color: #999;
    background-color: $primaryColor;
    width: 3px;
}


/* Цвет текста для меток в правой панели */
.right-panel-label {
    color: $headerTextColor;
}

/* Кнопки Сохранить, Отмена , Удалить*/
.footer-btn {
    width: 100px;
    // color: $primeDarkColor;
}

.my-fixed-width-btn {
    width: 90px;
}


.footer-btn:hover {
    background: $primaryColor;
    color: $secondaryColor;
}

.my-primary-btn {
    color: $primaryColor;
    border-color: $primaryColor;
}

.my-primary-btn:hover {
    color: $secondaryColor;
    background-color: $primaryColor;
}

.my-secondary-btn {
    color: $secondaryColor;
    background-color: $primaryColor;
    border-color: $primaryColor;
}

.my-secondary-btn:hover {
    color: $secondaryColor;
    background-color: darken($color: $primaryColor, $amount: 7);
}

.bg-login {
    background-color: #8257ab;
}

/* установка иконки лупы в поле ввода для поиска*/
.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.5rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

/*  чтобы Лупа была внутри поля ввода */
#inputSearch {
    padding-left: 2.375rem;
}

.loading-screen {
    background-color: #E4F0F0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1054;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-gif {
    max-width: 50%;
    max-height: 50%;
}