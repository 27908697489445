  .audio-player {
      display: flex;
      align-items: center;
      width: 100%;
    //   background: #fff;
    //   background: $secondaryColor;
    //   padding: 10px;
    //   border-radius: 10px;
    //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .play-pause-btn {
      margin-right: 10px;
      cursor: pointer;
      font-size: 24px;
      color: $primaryColor;
  }

        .seek-bar {
            flex: 1;
            margin: 0 10px;
            position: relative;
        }
    
        .seek-bar input {
            width: 100%;
            -webkit-appearance: none;
            /* Remove default styling */
            appearance: none;
            background: transparent;
            cursor: pointer;
        }
    
        .seek-bar input::-webkit-slider-runnable-track {
            width: 100%;
            height: 6px;
            background: #ddd;
            border-radius: 3px;
        }
    
        .seek-bar input::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 12px;
            height: 12px;
            background: $primaryColor;
            /* Фиолетовый цвет */
            border-radius: 50%;
            cursor: pointer;
            margin-top: -3px;
            /* Сдвиг для выравнивания */
        }
    
        .seek-bar input:focus {
            outline: none;
        }
    
        .seek-bar input::-moz-range-track {
            width: 100%;
            height: 6px;
            background: #ddd;
            border-radius: 3px;
        }
    
        .seek-bar input::-moz-range-thumb {
            width: 12px;
            height: 12px;
            background: $primaryColor;
            /* Фиолетовый цвет */
            border-radius: 50%;
            cursor: pointer;
        }
    
        .seek-bar input::-ms-track {
            width: 100%;
            height: 6px;
            background: transparent;
            border-color: transparent;
            color: transparent;
        }
    
        .seek-bar input::-ms-fill-lower {
            background: #ddd;
            border-radius: 3px;
        }
    
        .seek-bar input::-ms-fill-upper {
            background: #ddd;
            border-radius: 3px;
        }
    
        .seek-bar input::-ms-thumb {
            width: 12px;
            height: 12px;
            background: $primaryColor;
            /* Фиолетовый цвет */
            border-radius: 50%;
            cursor: pointer;
        }
//   .seek-bar {
//       flex: 1;
//       margin: 0 10px;
//       position: relative;
//   }

//   .seek-bar input {
//       width: 100%;
//   }

//   .seek-bar .handle {
//       position: absolute;
//       top: 50%;
//       left: 0;
//       width: 12px;
//       height: 12px;
//       background-color: #007bff;
//       border-radius: 50%;
//       transform: translate(-50%, -50%);
//       cursor: pointer;
//   }

  .time-display {
      margin: 0 10px;
      font-size: 14px;
      color: $primaryColor;
  }

.volume-control {
  position: relative;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
color: $primaryColor;
}

.volume-control input {
  display: none;
  position: absolute;
  transform: translateX(-50%);
  width: 100px;
  margin-top: 40px;
  -webkit-appearance: none;
  appearance: none;
}

.volume-control input::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 3px;
}

.volume-control input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: $primaryColor;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -3px; /* Сдвиг для выравнивания */
}

.volume-control input:focus {
  outline: none;
}

.volume-control input::-moz-range-track {
  width: 100%;
  height: 6px;
  background: #ddd;
  border-radius: 3px;
}

.volume-control input::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: $primaryColor;
  border-radius: 50%;
  cursor: pointer;
}

.volume-control input::-ms-track {
  width: 100%;
  height: 6px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.volume-control input::-ms-fill-lower {
  background: #ddd;
  border-radius: 3px;
}

.volume-control input::-ms-fill-upper {
  background: #ddd;
  border-radius: 3px;
}

.volume-control input::-ms-thumb {
  width: 12px;
  height: 12px;
  background: $primaryColor;
  border-radius: 50%;
  cursor: pointer;
}

.volume-control .volume-slider-container {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 120px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.volume-control input.show {
  display: block;
}
