$navbar-light-color:  $secondaryColor;
$navbar-light-brand-color:  $secondaryColor;
$input-btn-focus-color: $forthColor;
$input-focus-border-color: $seventhColor;
$navbar-light-brand-hover-color: $thirdColor;
$form-check-input-checked-bg-color: $primaryColor;
$modal-footer-border-width: 0px;

@import "~bootstrap/scss/bootstrap";

.form-switch.form-switch-lg {
    margin-bottom: 1rem;
}

.form-switch.form-switch-lg .form-check-input {
    height: 2rem;
    width: calc(3.5rem + 0.75rem);
    border-radius: 4rem;
}
