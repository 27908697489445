@import url(https://fonts.googleapis.com/css?family=PT+Sans+Caption);
// @import url(https://fonts.googleapis.com/css2?family=Roboto);

$backgroundColor: #EBECF0; // Измените цвет фона таблицы по желанию

// $borderColor: #b9b9b9;
$borderColor: $primaryColor;
// $hoverColor: rgb(3, 139, 191);
$hoverColor: $thirdColor;
// $green: rgb(126, 186, 55);
$green: $thirdColor;

//header theming
// $headerBackgroundColor: #EBECF0; //border to tabulator
$headerBackgroundColor: $secondaryColor; //border to tabulator
// $headerTextColor: #7D7F80; //header text color
$headerTextColor:  $primaryColor; //header text color
// $headerForegroundColor: #7D7F80;
$headerForegroundColor:  $primaryColor;
// $headerBorderColor: #c3bfbf6b; //header border color
// $headerSeparatorColor: #BFC1C3; //header bottom separator color
$headerMargin: 4px; //padding round header

$rowAltBackgroundColor : #FFF;
$rowBackgroundColor : #FFF;
$rowBorderColor : #FFF;
$rowHoverBackground: $fifthColor; //row background color on hover

$rowSelectedBackground: $forthColor !default; //row background color when selected
// $rowSelectedBackgroundHover: #cce3ff !default; //row background color when selected and hovered
$rowSelectedBackgroundHover:  $sixthColor !default; //row background color when selected and hovered

// @import "../../node_modules/tabulator-tables/src/scss/tabulator.scss"; //import table scss
@import "~tabulator-tables/src/scss/tabulator.scss";

.tabulator {
    border: none;
}

.tabulator .tabulator-header {
    font-family: 'PT Sans Caption';
    font-weight: normal;
    font-size: small;

    .tabulator-header-filter {
        display: none;
        }

    .tabulator-col {
        border-right: none; 
        .tabulator-col-content {
            padding: 14px;

        }

        //hold content of column header
        .tabulator-col-content {
            padding-left: 10px;
        }
    }
}

.tabulator-row {
    border-bottom: 1px solid $rowBorderColor;

    .tabulator-cell {
        font-family: 'PT Sans Caption';
        font-size: small;
        padding: 10px;
        padding-left: 10px;
        // border: $rowBorderColor;
        // border-right: 1px solid $rowBorderColor;
        // border-width: 13px;

    }

}

.tabulator .tabulator-footer {
    border-top: none;
    background-color: $headerBackgroundColor;
    color: $headerTextColor;
}

.tabulator-col {

    .tabulator-col-sorter {
        i {
            cursor: pointer;
            // transition: transform 0.1s ease-in-out;

            &:hover {
                // transform: scale(1.1);
            }
        }
    }

    &[aria-sort="none"] {
        .tabulator-col-sorter {
            i {
                cursor: pointer;
                color: $headerTextColor;

                &:hover {
                    color: $hoverColor;
                }
            }
        }
    }

    &[aria-sort="ascending"] {
        .tabulator-col-sorter {
            i {
                cursor: pointer;
                color: darken($hoverColor, 10%);

                &:hover {
                    color: $hoverColor;
                }
            }
        }
    }

    &[aria-sort="descending"] {
        .tabulator-col-sorter {
            i {
                color: darken($color: $green, $amount: 10%);
                transform: scaleY(-1);
                cursor: pointer;

                &:hover {
                    color: $green;
                        //    transform: scaleY(-1) scale(1.4);
                }
            }
        }
                                }
                                }
                
                                .tabulator-col-title {
                                    cursor: pointer;
                                    // transition: transform 0.1s ease;
                                        // transform-origin: left top;
                
                                    &:hover {
                                        color: $hoverColor // transform: scale(1.01);
    }
}
.tabulator .tabulator-alert {
    position: absolute;
    display: flex;
    align-items: center;

    top: 0;
    left: 0;
    z-index: 100;

    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.4);
    text-align: center;

    //loading message element
    .tabulator-alert-msg {
        display: inline-block;
        background: transparent;
        border: none;

        //loading message
        &.tabulator-alert-state-msg {
            border: none;
            background: transparent;
        }
    }
}

.tabulator-menu {
    .tabulator-menu-item {
        &:not(.tabulator-menu-item-disabled):hover {
            background: $rowHoverBackground;
        }
    }
}