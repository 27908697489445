$zindex-popover: 1040;

@import "~bootstrap/scss/bootstrap";

.popover {
    background-color: $secondaryColor;
}

.pop-title {
    color: $secondaryColor;
    background-color: $primaryColor;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

/* Стиль для иконки "закрыть" в обычном состоянии */
#pop-close .bi-x-lg {
    color: $thirdColor;
    /* Цвет иконки */
    transition: all 0.2s;
    /* Плавный переход состояний */
}

/* Стиль для иконки "закрыть" при наведении курсора */
#pop-close:hover .bi-x-lg {
    color: $secondaryColor;
    /* Цвет иконки при наведении */
    transform: scale(1.2);
    /* Увеличение иконки */
    cursor: pointer;
        /* Курсор в виде руки с пальцем */
}
.popover-filter .reset-button:hover {
    color: darken($color: $primaryColor, $amount: 20)
}

.popover-filter .reset-button {
    // border: none;
    // background: none;
    color: $primaryColor;
}

.popover-filter .apply-button:hover {
    color: darken($color: $primaryColor, $amount: 20)
}

.popover-filter .apply-button {
    color: $primaryColor;
    margin-left: auto;
}

.popover-user-info .exit-button:hover {
    color: darken($color: $primaryColor, $amount: 40)
    
}

.popover-user-info .exit-button {
    color: $primaryColor;
}

.popover-user-info .exit-btn-div {
    background-color: #e9e7e9;
}

